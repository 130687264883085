import React from 'react';
import Image from 'next/image';
import classNames from 'classnames/bind';

import AppPhoto from 'components/AppPhoto/AppPhoto';

import styles from './styles/AppAvatar.module.scss';

const HOST = process.env.NEXT_PUBLIC_SITE_BASE;
const PUBLIC = process.env.NEXT_PUBLIC_ASSETS;
const cx = classNames.bind(styles);

export interface AppAvatarProps {
  className?: string;
  nameClassName?: string;
  imageClassName?: string;
  bgColor?: string;
  user: UserModel;
  hideInfo?: boolean;
  onClick?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  size?: string;
  userLevelBadgeIfNotPro?: boolean;
  linkToUser?: boolean;
}

interface DimensionsType {
  [key: string]: number;
}

export const AppAvatar: React.FC<AppAvatarProps> = ({
  bgColor,
  className,
  nameClassName,
  imageClassName,
  user,
  hideInfo,
  onClick,
  linkToUser,
  size,
  userLevelBadgeIfNotPro,
}: AppAvatarProps) => {
  const hasBadge = user.admin ? 'admin' : user.pro ? 'pro' : null;

  const avatarClassNames = cx({
    avatar: true,
    'avatar--is-sm': size === 'sm',
    'avatar--is-md': size === 'md',
    'avatar--is-lg': size === 'lg',
    'avatar--is-clickable': onClick,
  });
  const dimensions: DimensionsType = {
    sm: 48,
    md: 56,
    lg: 96,
  };

  const avatarDimensions: number =
    size && dimensions[size] ? dimensions[size] : dimensions.sm;

  const content = (
    <>
      <div className={styles['avatar__image-container']}>
        {user.avatarUrl && (
          <AppPhoto
            className={`${styles['avatar__image']} ${imageClassName}`}
            src={user.avatarUrl}
            alt={user.name}
            options={{
              width: avatarDimensions,
              height: avatarDimensions,
              fit: 'crop',
              crop: 'smart',
            }}
            width={String(avatarDimensions)}
            height={String(avatarDimensions)}
          />
        )}
        {!user.avatarUrl && user.name && (
          <span
            style={{ backgroundColor: bgColor }}
            className={styles['avatar__image']}
          >
            {user.name.charAt(0)}
          </span>
        )}
        {hasBadge ? (
          <Image
            className={styles[`avatar__${hasBadge}`]}
            src={`${PUBLIC}/assets/badges/${hasBadge}.svg`}
            alt={`The Dyrt ${hasBadge.toUpperCase()} User`}
            width={20}
            height={20}
            loading="lazy"
          />
        ) : (
          userLevelBadgeIfNotPro &&
          user.userLevelImages && (
            <Image
              className={styles['avatar__badge']}
              src={user.userLevelImages.small}
              alt="user level badge"
              width={26}
              height={32}
              loading="lazy"
            />
          )
        )}
      </div>
      {!hideInfo && (
        <div className={styles['avatar__user-info']}>
          {user.name && (
            <span className={`${styles['avatar__name']} ${nameClassName}`}>
              {user.name}
            </span>
          )}
          {user.userLevelImages && user.userLevelName && (
            <div className={styles['avatar__user-level']}>
              <Image
                className={styles['avatar__level-badge']}
                src={user.userLevelImages.small}
                alt="user level badge"
                loading="lazy"
                width="12"
                height="16"
              />
              <span className={styles['avatar__level-title']}>
                {user.userLevelName}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );

  return linkToUser ? (
    <a
      href={`${HOST}/member/${user.slug}`}
      data-event="click_member_avatar"
      className={`${avatarClassNames} ${className}`}
    >
      {content}
    </a>
  ) : (
    <div
      className={`${avatarClassNames} ${className}`}
      role={onClick ? 'button' : ''}
      onClick={onClick}
      data-event="click_member_avatar"
    >
      {content}
    </div>
  );
};

export default AppAvatar;
